import React, { useState, useEffect } from 'react';
import styled from "styled-components";

const PageContainer = styled.div`
    position: absolute;
    background: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const LoaderContainer = styled.div`
    position: relative;
    width: 155px;
    height: 155px;
`;

const Loader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid forestgreen;
    border-top-color: transparent;
    border-radius: 50%;
    animation: circle infinite 0.75s linear;

    @keyframes circle {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Logo = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    object-fit: contain;
`;

const LoadingScreen = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <PageContainer>
                <LoaderContainer>
                    <Loader />
                    <Logo src="/logo.png" alt="Madagasikara Ekôlôjia Logo" />
                </LoaderContainer>
            </PageContainer>
        );
    }

    return children;
};

export default LoadingScreen;
