import React, { useEffect, useState } from 'react';
import './materials.css';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { FaEye } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import {config} from "../../config";
import {BsArrowRightShort} from "react-icons/bs";

const Materials = () => {
  const [galleryData, setGalleryData] = useState(
      JSON.parse(localStorage.getItem('galleryData')) || []
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAllModal, setShowAllModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    Aos.init({
      duration: 2000,
      once: true
    });

    const fetchGalleryData = async () => {
      try {
        const response = await fetch(`${config.baseUrl}/galeries`);
        const data = await response.json();

        // Only update if new data is different from localStorage
        const storedData = JSON.parse(localStorage.getItem('galleryData')) || [];
        if (JSON.stringify(data) !== JSON.stringify(storedData)) {
          setGalleryData(data);
          localStorage.setItem('galleryData', JSON.stringify(data));
        }
      } catch (error) {
        console.error('Error fetching gallery data:', error);
        // If fetch fails, keep existing localStorage data
      }
    };

    fetchGalleryData();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handleShowAll = () => {
    setShowAllModal(true);
    setCurrentImageIndex(0);
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  // Show only the first 4 images initially
  const displayedImages = galleryData.slice(0, 4);

  return (
      <div className="materials container section">
        <div className="sectionContainer">
          <h2 data-aos="fade-up">Galerie</h2>

          <div className="materialsContainer flex" data-aos="fade-up">
            {displayedImages.map(({id, image, title}) => (
                <div className="singleMaterial" key={id}>
                  <img src={image} alt={title} className="materialImage"/>

                  <div className="materialDetails">
                    <div className="materialPicture">
                      <FaEye
                          className="eyeIcon"
                          onClick={() => handleImageClick(image)}
                      />
                    </div>
                    <div className="ownerName">
                      <span>{title}</span>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>

        {galleryData.length > 4 && (
            <>
              <h3 className="seeAll" onClick={handleShowAll}>
                Voir tout
                <BsArrowRightShort className='iconSeeAll'/>
              </h3>
            </>
        )}

        {showAllModal && (
            <div className="modal">
              <div className="modalContent showAllModal">
              <span onClick={() => setShowAllModal(false)}>
                <MdClose className="closeButton" />
              </span>

                {/* Main Image Display */}
                <div className="mainImageContainer">
                  <img
                      src={galleryData[currentImageIndex].image}
                      alt={galleryData[currentImageIndex].title}
                      className="mainImage"
                  />
                </div>

                {/* Thumbnail Strip */}
                <div className="thumbnailContainer">
                  <div className="thumbnailStrip">
                    {galleryData.map((item, index) => (
                        <div
                            key={item.id}
                            className={`thumbnailItem ${
                                currentImageIndex === index ? 'activeThumbnail' : ''
                            }`}
                            onClick={() => handleThumbnailClick(index)}
                        >
                          <img
                              src={item.image}
                              alt={item.title}
                              className="thumbnailImage"
                          />
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
        )}

        {selectedImage && (
            <div className="modal" onClick={closeModal}>
              <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                <MdClose
                    className="closeButton"
                    onClick={() => setSelectedImage(null)}
                />
                <img src={selectedImage} alt="Detail view" className="modalImage" />
              </div>
            </div>
        )}
      </div>
  );
};

export default Materials;