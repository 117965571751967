import React from 'react'
import './app.css'
import Footer from './Components/Footer/Footer'
import Home from './Components/Home/Home'
import Main from './Components/Main/Main'
import Materials from './Components/Materials/Materials'
import Navbar from './Components/Navbar/Navbar'
import Activities from './Components/Activities/Activities'
import Partner from "./Components/Partner/Partner";
import LoadingScreen from "./LoadingScreen";
import CoreTeam from "./Components/Core Team/CoreTeam";

function App() {
    return (
        <LoadingScreen>
            <div className="App">
                <Navbar/>

                <div id="home">
                    <Home/>
                </div>
                <div id="about">
                    <Main/>
                    <CoreTeam/>
                </div>
                <div id="activities">
                    <Activities/>
                </div>
                <div id="gallery">
                    <Materials/>
                </div>
                <div id="partners">
                    <Partner/>
                </div>
                <div id="contact">
                    <Footer/>
                </div>

            </div>
        </LoadingScreen>
    );
}

export default App