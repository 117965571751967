import React, { useEffect, useState } from 'react'
import './footer.css'
import video from '../../Assets/sail.mp4'
import {FiSend} from 'react-icons/fi'
import {FiChevronRight} from 'react-icons/fi'
import {config} from "../../config";

import image from '../../Assets/logo.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 2000,
      once: true
    })
  }, [])

  // Smooth scroll function
  const scrollToSection = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionStatus('');
    setIsLoading(true);
    setSubmissionSuccess(false);

    // Basic validation
    if (!email.trim() || !message.trim()) {
      setSubmissionStatus('Veuillez remplir tous les champs');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${config.baseUrl}/contact_us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message,
        }),
      });

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        setSubmissionStatus('Message envoyé avec succès !');
        setSubmissionSuccess(true);
        // Clear form
        setEmail('');
        setMessage('');
      } else {
        setSubmissionStatus(responseData.message || 'Erreur lors de lenvoi du message');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmissionStatus('Erreur de connexion. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
      <section className='footer'>

        <div className="videoDiv">
          <video src={video} loop autoPlay muted type="video/mp4"></video>
        </div>

        <div className="secContent container">
          <div className="contactDiv flex">

            <div className="text">
              <h2> Nous aimerions connaître votre avis </h2>
              <small> Si vous avez des questions à propos notre
                organisation, nous nous mettons entièrement à votre disposition. </small>

              <h2> Restez en contact avec nous </h2>
              <small> Contactez-nous et ensemble, contribuons au renforcement de capacité de nos acteurs en
                développement durable. </small>
              <br/>
              <small> Ecrivez-nous au <b className="email"> madagasikarekolojia@gmail.com </b> pour développer de la
                synergie. </small>
              <br/>
              <br/>
              <small> Adresse: IA 41 B Antalamohitra - Ambohijanahary - Ambohibao - 101 Antananarivo </small>
            </div>

            <form onSubmit={handleSubmit} className="inputDiv row">
              <input
                  className="text"
                  placeholder="Entrez votre email..."
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isLoading}
              />
              <textarea
                  className="textarea"
                  placeholder="Entrez votre message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  disabled={isLoading}
              />
              {submissionStatus && (
                  <div style={{
                    color: submissionSuccess
                        ? 'hsl(147, 92%, 43%)'
                        : 'red'
                  }}>
                    {submissionStatus}
                  </div>
              )}
              <button
                  className='btn sendMessage flex'
                  type='submit'
                  disabled={isLoading}
              >
                {isLoading ? (
                    <>
                      Envoi en cours
                      <div className="spinner-container">
                        <div className="spinner"></div>
                      </div>
                    </>
                ) : (
                    <>
                      Envoyer <FiSend className="icon"/>
                    </>
                )}
              </button>
            </form>

          </div>

          <div className="footerCard flex">
            <div className="footerIntro flex">

              <div className="logoDiv">
                <div className='logo flex' onClick={() => scrollToSection('home')}>
                  <img src={image} alt=""/>
                  <div className="textNextLogo">
                    <h1>
                      Madagasikara Ekôlôjia
                    </h1>
                    <div className="footerParagraph">
                      La Biodiversité garant d'un développement durable et équitable !
                    </div>
                  </div>
                </div>
              </div>

              <h1>
                Contact
              </h1>

              <div className="footerContactSection" style={{flexDirection: 'column'}}>
                <div className="footerContact flex">
                  <img
                      src="https://cdn-ilcckdj.nitrocdn.com/QlUnTVTrZOOqlaTqMQtiukCnxjpNglhu/assets/images/optimized/rev-262d696/www.yas.mg/wp-content/uploads/2024/02/jem_logo-1.svg"
                      className="contactImage" alt="Yas"
                  />
                  <a href="tel:+261346134210" className="contactLink">
                    +261 34 61 342 10
                  </a>
                </div>
                <div className="footerContact flex">
                  <img
                      src="https://www.orangedigitalcenters.com/orange_master_logo.svg"
                      className="contactImage"
                      style={{borderRadius: "5px"}} alt="Orange"
                  />
                  <a href="tel:+261324000999" className="contactLink">
                    +261 32 40 009 99
                  </a>
                </div>
              </div>
            </div>

            <div className="footerLinks grid">

              <div data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
                A propos de nous
              </span>

                <li className="footerList flex">
                  <div onClick={() => scrollToSection('home')}>
                    <FiChevronRight className="icon"/>
                    Accueil
                  </div>
                </li>
                <li className="footerList flex">
                  <div onClick={() => scrollToSection('about')}>
                    <FiChevronRight className="icon"/>
                    A propos
                  </div>
                </li>
              </div>

              <div data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
                Découvrir
              </span>

                <li className="footerList flex">
                  <div onClick={() => scrollToSection('activities')}>
                    <FiChevronRight className="icon"/>
                    Actualités
                  </div>
                </li>
                <li className="footerList flex">
                  <div onClick={() => scrollToSection('gallery')}>
                    <FiChevronRight className="icon"/>
                    Galerie
                  </div>
                </li>
              </div>

            </div>

            <div className="footerDiv flex">
              <small>
                Tout droits réservés © Madeko
              </small>
            </div>

          </div>
        </div>

      </section>
  )
}

export default Footer