import React, { useEffect, useState } from 'react';
import './main.css';

import Aos from 'aos';
import 'aos/dist/aos.css';
import {config} from "../../config";


const Main = () => {
  const [aboutData, setAboutData] = useState(
      JSON.parse(localStorage.getItem('aboutData')) || []
  );

  useEffect(() => {
    Aos.init({
      duration: 2000,
      once: true
    });

    const fetchAboutData = async () => {
      try {
        const response = await fetch(`${config.baseUrl}/abouts/03504203-cece-446c-b471-2803fded8674`);
        const data = await response.json();

        // Only update if new data is different from localStorage
        const storedData = JSON.parse(localStorage.getItem('aboutData')) || [];
        if (JSON.stringify(data) !== JSON.stringify(storedData)) {
          setAboutData(data);
          localStorage.setItem('aboutData', JSON.stringify(data));
        }
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchAboutData();
  }, []);

  const { description, information, sharedMessage, members, image } = aboutData;

  const renderWithLineBreaks = (text: string) => {
    if (!text) return null;

    return text.split('\n').map((line, index) => {
      // Trim the line to remove leading/trailing whitespace
      const trimmedLine = line.trim();

      // Check if the line starts with a dash and is not just a dash
      if (trimmedLine.startsWith('-') && trimmedLine !== '-') {
        // Remove the dash and any following whitespace
        const bulletText = trimmedLine.replace(/^-\s*/, '');

        return (
            <div key={index} className="bullet-list-item" style={{ paddingLeft: '30px', position: 'relative' }}>
                <span
                    style={{
                      position: 'absolute',
                      paddingLeft: '10px',
                      left: 0,
                    }}
                >
                  •
                </span>
                {bulletText}
            </div>
          );
      }

      // If not a bullet point, return as a regular line with a line break
      return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
      );
    });
  };

  return (
      <div className="support container section">
        <div className="sectionContainer">
          <div className="titlesDiv">
            <h2>A propos de nous</h2>
            <p>{renderWithLineBreaks(description)}</p>
          </div>

          <div className="infoDiv grid">
            <div className="textDiv grid">
              <div className="singleInfo" data-aos="fade-right">
                <span className="number colorOne">01</span>
                <h4>A Savoir</h4>
                <p>{renderWithLineBreaks(information)}</p>
              </div>

              <div className="singleInfo" data-aos="fade-right">
                <span className="number colorTwo">02</span>
                <h4>Madeko s'adresse à vous</h4>
                <p>{renderWithLineBreaks(sharedMessage)}</p>
              </div>

              <div className="singleInfo" data-aos="fade-right">
                <span className="number colorThree">03</span>
                <h4>Membres</h4>
                <p>{renderWithLineBreaks(members)}</p>
              </div>
            </div>

            <div className="imgDiv" data-aos="fade-left">
              <img src={image} alt="Madeko" />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Main;
