import React, { useEffect, useState } from 'react';
import './activities.css';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { config } from "../../config";
import { IoReader } from "react-icons/io5";
import { MdClose } from "react-icons/md";

const Activities = () => {
    const [activityData, setActivitiesData] = useState(
        JSON.parse(localStorage.getItem('activityData')) || []
    );
    const [activeActivity, setActiveActivity] = useState(null);

    useEffect(() => {
        Aos.init({
            duration: 2000,
            once: true
        });

        // Fetch activities data from the API
        const fetchActivities = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/activities`);
                const data = await response.json();
                const updatedData = data.map((item) => ({
                    ...item,
                    mediaType: item.image.endsWith('.mp4') ? 'video' : 'image',
                }));

                // Only update if new data is different from localStorage
                const storedData = JSON.parse(localStorage.getItem('activityData')) || [];
                if (JSON.stringify(updatedData) !== JSON.stringify(storedData)) {
                    setActivitiesData(updatedData);
                    localStorage.setItem('activityData', JSON.stringify(updatedData));
                }
            } catch (error) {
                console.error('Error fetching activities data:', error);
            }
        };

        fetchActivities();
    }, []);

    const MediaContent = ({ media, mediaType }) => {
        if (mediaType === 'video') {
            return (
                <video muted autoPlay loop>
                    <source src={media} type="video/mp4" />
                </video>
            );
        } else if (mediaType === 'image') {
            return <img src={media} alt="Activity" />;
        }
        return null;
    };

    const handleReadMore = (activity) => {
        setActiveActivity(activity);
    };

    const closeArticle = () => {
        setActiveActivity(null);
    };

    return (
        <section className="info section container">
            <section className="secContainer">
                <div className="titlesDiv">
                    <h2> Actualités </h2>
                    <p>
                        En alignement avec les Objectifs de Développement Durable (ODD),<span style={{ color: "red" }}> l'ONG MADAGASIKARA EKOLOJIA </span>  a pour but de fonder une compétence citoyenne suffisamment mûre en termes de compréhension et de gestion de risques et d'opportunités en Biodiversité et services écosystémiques tout en assurant un rôle de levier de <span style={{ color: "red" }}> développement durable tant pour Madagascar que pour l'Afrique </span>.
                    </p>
                </div>

                <div className="mainContent grid">
                    {activityData.map((activity) => (
                        <div className="filInfo" data-aos="fade-in" key={activity.id}>
                            <div className="infoImage">
                                <MediaContent className="media" media={activity.image} mediaType={activity.mediaType} />
                                <div className="overlayInfo">
                                    <h2>{activity.title}</h2>
                                    <p>{activity.description}</p>
                                    <IoReader className='icon' onClick={() => handleReadMore(activity)} />
                                </div>
                            </div>

                            <div className="infoFooter">
                                <div className="number">
                                    {activity.id < 10 ? `0${activity.id}` : activity.id} - <span>{activity.title}</span>
                                </div>
                                <div className="infoText flex">
                                    <p className="twoLineText">{activity.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {activeActivity && (
                    <div className="newspaper-article">
                        <div className="newspaper-content">
                            <div style={{margin: "1.5rem 0"}}>
                                <h1> {activeActivity.title} </h1>
                                <MdClose className="closeButton" onClick={closeArticle}/>
                            </div>
                            <div className="intro">
                                {activeActivity.description.split('\n\n').map((paragraph, index) => (
                                    <React.Fragment key={index}>
                                        {paragraph}
                                        <br/><br/>
                                    </React.Fragment>
                                ))}
                            </div>
                            <MediaContent media={activeActivity.image} mediaType={activeActivity.mediaType}/>
                            <p>
                                Photo présenté par <span
                                style={{color: "orangered"}}> {activeActivity.copyright} </span>
                            </p>

                            <div className="intro">
                                {activeActivity.content.split('\n\n').map((paragraph, index) => (
                                    <React.Fragment key={index}>
                                        {paragraph}
                                        <br/><br/>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </section>
    );
};

export default Activities;