import React, {useEffect} from "react";
import styled from "styled-components";
import Aos from 'aos'
import 'aos/dist/aos.css'

const Container = styled.div`
    padding: 0.5rem 7rem;

    @media (max-width: 1200px) {
        padding: 2rem;
    }
`;

const Header = styled.h2`
    font-weight: bold;
    margin: 3rem 0;
    text-align: center;
    color: #000;
`;

const Intro = styled.p`
    margin-bottom: 2rem;
    text-align: justify;
    color: var(--blackColor);
`;

const TeamSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    background-color: #ddd;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
`;

const DetailsContainer = styled.div`
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const MemberContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 2rem;

    &:nth-child(odd) {
        flex-direction: row;
    }

    &:nth-child(even) {
        flex-direction: row-reverse;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;

        &:nth-child(even),
        &:nth-child(odd) {
            flex-direction: column;
        }
    }
`;

const Name = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
`;

const Role = styled.a`
    font-size: 1rem;
    font-weight: bold;
    color: var(--Madeko);
    text-decoration: none;
`;

const Description = styled.p`
    font-size: 0.95rem;
    line-height: 1.5;
    color: #666;
`;

const TeamMember = ({ name, role, description, imageUrl, fadeDirection }) => (
    <MemberContainer
        data-aos={fadeDirection}
        data-aos-duration="2000"
        data-aos-once="true"
    >
        <ImageContainer style={{ backgroundImage: `url(${imageUrl})` }} />
        <DetailsContainer>
            <Name>{name}</Name>
            <Role>{role}</Role>
            <Description>{description}</Description>
        </DetailsContainer>
    </MemberContainer>
);

const CoreTeam = () => {
    useEffect(() => {
        Aos.init({
            duration: 2000,
            once: true
        });
    }, []);

    return (
        <Container>
            <Header>Le coeur de l'équipe</Header>
            <Intro>
                Les fondateurs de l'ONG MADAGASIKARA EKÔLÔJIA, ayant été rassemblés par leurs affinités, ont pris l'initiative de créer une entité qui se propose d'être <span style={{ color: "orangered" }}>un lien de type synaptique</span> (terminaison nerveuse) entre les différentes parties prenantes du paysage de <span style={{ color: "orangered" }}>développement durable de Madagascar</span>.
            </Intro>
            <TeamSection>
                <TeamMember
                    name="Johny Rabenantoandro"
                    role="Président"
                    description="Botaniste, Spécialiste Expert international en Programme Biodiversité et Développement."
                    imageUrl="https://media.licdn.com/dms/image/v2/D5622AQHkOJTguOe1Kw/feedshare-shrink_800/feedshare-shrink_800/0/1716541719452?e=2147483647&v=beta&t=_eMWMqTvIwnNNB0UYk82sLDKz_-fjcT-ABHAmO0rPmo"
                    fadeDirection="fade-right"
                />
                <TeamMember
                    name="Dr. Lucien Faliniaina"
                    role="Vice-président"
                    description="Maître de Conférences, Biologie et Ecologie Végétales (Anatomie, Taxonomie) et Horticulteur"
                    imageUrl="https://ehfupwzhxybqozoosqlu.supabase.co/storage/v1/object/public/images/2024/November/Faliniaina.jpg"
                    fadeDirection="fade-left"
                />
                <TeamMember
                    name="Nelson Rabenandrianina"
                    role="Secrétaire Général"
                    description="Maître Ecologie Végétale."
                    imageUrl="https://media.licdn.com/dms/image/v2/C4D03AQGIh1P_rjYKVA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1587228204317?e=1739404800&v=beta&t=HtLdEBhswY0tHbkWYGxqgO623UHk_ojFsR6LLnr0WyU"
                    fadeDirection="fade-right"
                />
            </TeamSection>
        </Container>
    );
};

export default CoreTeam;