import React, { useState, useEffect } from 'react'
import './navbar.css'
import { TbGridDots } from 'react-icons/tb'
import { AiFillCloseCircle } from 'react-icons/ai'
import image from '../../Assets/logo.png'

const Navbar = () => {
    const [active, setActive] = useState('navBar')
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            // You can adjust this value as needed
            const scrollThreshold = 50
            setIsScrolled(window.scrollY > scrollThreshold)
        }

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll)

        // Cleanup the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const showNav = () => {
        setActive('navBar activeNavbar')
    }

    const removeNav = () => {
        setActive('navBar')
    }

    // Smooth scroll function
    const scrollToSection = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            // Smooth scroll
            element.scrollIntoView({ behavior: 'smooth' });

            // Close mobile nav menu if open
            removeNav();
        }
    }

    return (
        <section className='navBarSection'>
            <header className={`header flex ${isScrolled ? 'scrolled' : ''}`}>
                <div className='logoDiv'>
                    <div className="logo flex" onClick={() => scrollToSection('home')}>
                        <img src={image} alt="" />
                        <h1> Madeko </h1>
                    </div>
                </div>
                <div className={active}>
                    <ul className="navLists flex">
                        <li className="navItem">
                            <div
                                onClick={() => scrollToSection('home')}
                                className="navLink"
                            >
                                Accueil
                            </div>
                        </li>
                        <li className="navItem">
                            <div
                                onClick={() => scrollToSection('about')}
                                className="navLink"
                            >
                                A propos
                            </div>
                        </li>
                        <li className="navItem">
                            <div
                                onClick={() => scrollToSection('activities')}
                                className="navLink"
                            >
                                Actualités
                            </div>
                        </li>
                        <li className="navItem">
                            <div
                                onClick={() => scrollToSection('gallery')}
                                className="navLink"
                            >
                                Galerie
                            </div>
                        </li>
                        <li className="navItem">
                            <div
                                onClick={() => scrollToSection('partners')}
                                className="navLink"
                            >
                                Nos partenaires
                            </div>
                        </li>
                        <button
                            className="btn"
                            onClick={() => scrollToSection('contact')}
                        >
                            Contact
                        </button>
                    </ul>
                    <div onClick={removeNav} className="closeNavbar">
                        <AiFillCloseCircle className="icon"/>
                    </div>
                </div>
                <div onClick={showNav} className="toggleNavbar">
                    <TbGridDots className="icon"/>
                </div>
            </header>
        </section>
    )
}

export default Navbar