import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {config} from "../../config";
import Aos from 'aos'
import 'aos/dist/aos.css'

const HomePageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    gap: 3rem;

    @media (max-width: 800px) {
        flex-direction: column;
        padding: 2rem;
        justify-content: space-between;
        gap: 5rem;
    }

    @media (min-width: 800px) and (max-width: 1200px) {
        padding: 2rem;
    }
`;

const TextContent = styled.div`
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
        flex: 1;
    }
`;

const Title = styled.h2`
    font-weight: bold;
    margin-bottom: 1rem;
`;

const Description = styled.p`
    font-size: 1rem;
    text-align: justify;
    margin-bottom: 2rem;
`;

const LogoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    max-width: 500px;
    gap: 3rem;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const LogoCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const Logo = styled.img`
    max-width: 150px;
    width: 100%;
`;

const PartnerName = styled.span`
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
`;

const ContactUsButton = styled.button`
    background-color: var(--Madeko);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 1rem 2rem;
    font-size: 1rem;
    cursor: pointer;
`;

const Partner = () => {
    const [partnerData, setPartners] = useState(
        JSON.parse(localStorage.getItem('partnerData')) || []
    );

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/partners`);
                const data = await response.json();

                // Only update if new data is different from localStorage
                const storedData = JSON.parse(localStorage.getItem('partnerData')) || [];
                if (JSON.stringify(data) !== JSON.stringify(storedData)) {
                    setPartners(data);
                    localStorage.setItem('partnerData', JSON.stringify(data));
                }
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };

        fetchPartners();
    }, [partnerData.length]);

    // Smooth scroll function
    const scrollToSection = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            // Smooth scroll
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        Aos.init({
            duration: 2000,
            once: true
        });
    }, []);

    return (
        <HomePageContainer>
            <TextContent
                data-aos-duration="2000"
                data-aos-once="true"
                data-aos="fade-right"
            >
                <Title> Nous travaillons avec les meilleurs des partenaires </Title>
                <Description>
                    Madagasikara Ekôlôjia se propose d'être un "<span style={{ color: "red" }}>lien de type synaptique</span>", à l'image du neurone, entre <span style={{ color: "red" }}> les principaux acteurs </span> qui sont déjà en œuvre pour trouver un modèle de développement inclusif, durable et équitable.
                </Description>
                <ContactUsButton onClick={() => scrollToSection('contact')}>Nous Contacter</ContactUsButton>
            </TextContent>
            <LogoContainer
                data-aos-duration="2000"
                data-aos-once="true"
                data-aos="fade-left"
            >
                {partnerData.map((partner) => (
                    <LogoCard key={partner.id}>
                        <Logo src={partner.logo} alt={partner.name} />
                        <PartnerName>{partner.name}</PartnerName>
                    </LogoCard>
                ))}
            </LogoContainer>
        </HomePageContainer>
    );
};

export default Partner;
