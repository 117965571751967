import React, { useEffect, useState, useRef } from 'react';
import './home.css';
import {config} from "../../config";

const Home = () => {
    const [banners, setBanners] = useState(
        JSON.parse(localStorage.getItem('bannerData')) || []
    );
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const carouselInterval = useRef(null);
    const touchStartX = useRef(null);

    useEffect(() => {
        // Fetch banners from the API
        const fetchBanners = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/banners`);
                const data = await response.json();

                // Only update if new data is different from localStorage
                const storedData = JSON.parse(localStorage.getItem('bannerData')) || [];
                if (JSON.stringify(data) !== JSON.stringify(storedData)) {
                    setBanners(data);
                    localStorage.setItem('bannerData', JSON.stringify(data));
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        fetchBanners();
    }, []);

    useEffect(() => {
        // Setup carousel for images
        const images = banners.filter(banner => !banner.banner.endsWith('.mp4') && banner.isActive);

        if (images.length > 1) {
            carouselInterval.current = setInterval(() => {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
            }, 7000);

            return () => clearInterval(carouselInterval.current);
        }
    }, [banners]);

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        if (!touchStartX.current) return;

        const touchEndX = e.touches[0].clientX;
        const diffX = touchStartX.current - touchEndX;

        const images = banners.filter(banner => !banner.banner.endsWith('.mp4') && banner.isActive);

        if (Math.abs(diffX) > 50) {
            if (diffX > 0) {
                // Swipe left, go to next image
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
            } else {
                // Swipe right, go to previous image
                setCurrentImageIndex(prevIndex =>
                    prevIndex === 0 ? images.length - 1 : prevIndex - 1
                );
            }
            touchStartX.current = null;
        }
    };

    const activeVideos = banners.filter(banner => banner.banner.endsWith('.mp4') && banner.isActive);
    const activeImages = banners.filter(banner => !banner.banner.endsWith('.mp4') && banner.isActive);

    const handleDotClick = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <section className="home">
            <div className="overlay"></div>

            {/* Render video if available */}
            {activeVideos.length > 0 && (
                <video
                    src={activeVideos[0].banner}
                    muted
                    autoPlay
                    loop
                    playsInline
                    style={{ transition: 'opacity 0.5s ease-in-out' }}
                ></video>
            )}

            {/* Render image carousel if no video */}
            {activeVideos.length === 0 && activeImages.length > 0 && (
                <div
                    className="carousel"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    {activeImages.map((image, index) => (
                        <img
                            key={image.banner}
                            src={image.banner}
                            alt={image.title}
                            style={{
                                display: index === currentImageIndex ? 'block' : 'none',
                                opacity: index === currentImageIndex ? 1 : 0,
                                animation: index === currentImageIndex ? 'fadeIn 0.5s ease-in-out' : 'none'
                            }}
                        />
                    ))}

                    {/* Dot indicators */}
                    <div className="carousel-dots">
                        {activeImages.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                                onClick={() => handleDotClick(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            )}

            <div className="homeContent container">
                <div className="textDiv">
                    {/* Render title and description for active images */}
                    {activeImages.length > 0 && (
                        <div key={activeImages[currentImageIndex].title}>
                            <h1 className="homeTitle">
                                {activeImages[currentImageIndex].title}
                            </h1>
                            <span className="smallText">
                                {activeImages[currentImageIndex].description}
                            </span>
                        </div>
                    )}

                    {/* Render title and description for active video when no images */}
                    {activeImages.length === 0 && activeVideos.length > 0 && (
                        <div key={activeVideos[0].title}>
                            <h1 className="homeTitle">
                                {activeVideos[0].title}
                            </h1>
                            <span className="smallText">
                                {activeVideos[0].description}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Home;